<template>
  <LcLotteryTeaser
    v-bind="{
      backgroundPattern,
      badge,
      drawDate,
      headline,
      jackpot,
      primaryButtonProps,
      secondaryButtonProps,
      subline,
    }"
    :aria-label="id"
    :cutoff-time="closingDate"
    :draw-date-headline="$t('lottery.teaser.drawdate')"
    :style="theme"
    :variant="lotteryTeaserVariant"
    @outdated="emit('update-teasers')"
    @primary-button-click="addBonusCode"
    @secondary-button-click="addBonusCode"
  >
    <template #lottery-logo>
      <LotteryLogo
        v-bind="{ licensedTerritory }"
        :lottery-id="id"
        size="fluid"
      />
    </template>
  </LcLotteryTeaser>
</template>

<script lang="ts" setup>
import type { LcLotteryTeaserVariant } from '@lottocom/frontend-components'

import type {
  CampaignPayload,
  LotteryId,
  Money,
  QuickPickConfiguration,
} from '~/@types/generated/backend/graphql-schema-types'

export type LotteryTeaserProps = {
  campaign?: Maybe<CampaignPayload>
  closingDate: string
  comingSoon: boolean
  drawDate: string
  id: LotteryId
  jackpotSize: Money
  licensedTerritory: string
  quickPick?: Maybe<Pick<QuickPickConfiguration, 'plays'>>
  variant?: LcLotteryTeaserVariant
}

type LotteryTeaserEmit = {
  (e: 'update-teasers'): void
}

const emit = defineEmits<LotteryTeaserEmit>()
const props = defineProps<LotteryTeaserProps>()

const { getTranslation, t } = useI18nUtils()

const headline = computed(() => {
  if (subline.value) return

  return getTranslation({
    fallback: t('lottery.teaser.headline'),
    key: `lottery.teaser.headline.${props.id}`,
  })
})
const jackpot = computed(() => ({
  ...props.jackpotSize,
  placeholder: props.comingSoon
    ? t('lottery.teaser.jackpot.placeholder')
    : undefined,
}))
const primaryButtonProps = computed(() => ({
  ariaLabel: t('lottery.teaser.button.playnow.arialabel', {
    lottery: t(`lottery.${props.id}`),
  }),
  text: t('lottery.teaser.button.playnow'),
  to: {
    path: `/${props.id.toLowerCase()}`,
    query: {
      newLine: '1',
    },
  },
}))
const secondaryButtonProps = computed(() => {
  // TODO: Remove variant check if SF-4966 has been released
  if (!props.quickPick || props.variant === 'expressive') return
  return {
    ariaLabel: t('lottery.teaser.button.quickpick.arialabel', {
      count: props.quickPick.plays,
      lottery: t(`lottery.${props.id}`),
    }),
    text: t(
      'lottery.teaser.button.quickpick',
      { count: props.quickPick.plays },
      props.quickPick.plays,
    ),
    to: {
      path: `/${props.id.toLowerCase()}/options`,
      query: {
        quickpick: 'true',
      },
    },
  }
})
const subline = computed(
  () =>
    getTranslation({
      key: `lottery.teaser.subline.${props.id}.${props.licensedTerritory}`,
    }) ||
    getTranslation({
      key: `lottery.teaser.subline.${props.id}`,
    }),
)
const theme = computed(() => {
  const licensedTerritory = props.licensedTerritory.toLowerCase()
  const lotteryId = props.id.toLowerCase()

  return {
    '--lottery-jackpot-color': `var(--${lotteryId}-${licensedTerritory}-color-primary, var(--${lotteryId}-color-primary))`,
    '--lottery-teaser-frame-color': `var(--${lotteryId}-${licensedTerritory}-color-primary, var(--${lotteryId}-color-primary))`,
  }
})

const { campaign, variant } = toRefs(props)
const { addBonusCode, backgroundPattern, badge, lotteryTeaserVariant } =
  useOnsiteMarketingTeaser(campaign, variant)
</script>
